













































































































































































import { Component, Vue } from "vue-property-decorator";
import { RegViewModel } from "../../models/RegViewModel";
import AuthService from "../../services/AuthService";
import AlertService from "../../../common-app/service/AlertService";
import AuthUser from "../../../global-config/AuthUser";

@Component({
  components:{},
})
export default class Registration extends Vue {
  public submitted: boolean = false;
  public user: RegViewModel = {} as RegViewModel;

  registrationWithGoogle() {
    const generateRandomString = (length: number) => {
    return Math.random().toString(36).substring(2, length + 2);
    };
   
    const clientId='329868347963-vqohivsqlsa8vl3ieen8tjl2el8fb5uo.apps.googleusercontent.com';
    //const clientId = '1065902859762-o13g9dm9hsu01bh18ldugrtv4284o0iv.apps.googleusercontent.com'; // Replace with your actual client ID
    const redirectUri = 'https://bddot.com/Registration'; // Ensure this matches the console
    const scope = 'openid profile email';
    const responseType = 'code';
    const accessType = 'offline'; // To request a refresh token
    const state = generateRandomString(16); // Optional, but good for CSRF protection

    // Construct the Google authorization URL
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${state}`;
// Redirect to the Google authorization URL
window.location.href = googleAuthUrl;
    
  }
  mounted() {
   // Extract query parameters from the URL after redirection from Google
  const queryParams = new URLSearchParams(window.location.search);

  // Get the authorization code
  const authorizationCode = queryParams.get('code');
  if (authorizationCode) {
   // Send the code to your backend for further processing (e.g., exchanging for a token)
    this.exchangeAuthorizationCode(authorizationCode);
  } else {
    //console.error('Authorization code not found in URL');
  }
  }
 

  exchangeAuthorizationCode(authorizationCode: string) {
  //console.log("Code :"+authorizationCode);
    
    // Call the backend API to exchange the authorization code for a token
    AuthService.googleLogin({ code: authorizationCode,isLogIn: false })
    .then(response => {
      this.submitted = true;
        // Check if the response is valid and user is authorized
        //console.log("response :"+response;
        //console.log("response data :"+response.data);
        //console.log("token :"+response.data.token);
      
        if (response && response.data && response.data.isAuthorized) {
          
           // console.log("Registration successful!", response.data);
            // Set the authentication token for the user
            AuthUser.setToken(response.data);
            // Redirect to the user home page after successful login
            this.$router.push({ path: "user" }); // Change to "home" if that's the desired route
        } 
        else {
         // Log authorization failure and notify the user
          //  console.warn('Authorization failed. Please check the response.', response.data);
          AlertService.sendMessage(response.data.message || 'Authorization failed. Please try again.');
        }
    })
    .catch(error => {
        // Handle any errors that occur during the API call
       // console.error("Registration failed"+error);
        AlertService.sendMessage("Registration failed. Please try again.");
    });
}

  created() {
    const dict = {
      custom: {
        firstName: {
          required: "Your full name is required.",
        },
        phone: {
          required: "The phone is required.",
          numeric: "The phone number can only contain numeric characters.",
          min: "The phone number must be at least 11 characters.",
          max: "Phone number cannot be more than 11 characters.",
        },
        password: {
          required: "A password is required.",
          min: "The password must be at least 6 characters.",
        },
      },
    };

    this.$validator.localize("en", dict);
  }

  onSubmit() {
    this.$validator.validate().then((valid: any) => {
      if (valid) {
        this.submitted = true;
        AuthService.registration(this.user)
          .then((response) => {
            let result = response.data as any;
            if (result.status) {
              //console.log(result.result.securityVerification.code);
              this.$store.dispatch("addVerify", {
                userId: result.result.user.id,
                verifycode: result.result.securityVerification.code,
              });
              this.$router.push({ path: "registration-verify" });
            } else {
              this.submitted = false;
              if (result.messageType == "unique") {
                this.$validator.errors.add({
                  field: "phone",
                  msg: result.message,
                });
              } else {
                AlertService.sendMessage(result.message);
              }
            }
          })
          .catch(() => {
            this.submitted = false;
          });
      }
    });
  }

  public registrationWithFacebook() {
 // console.log('Facebook registration triggered');
 const appId = '521579997304433'; // Your Facebook App ID
 //const appId = '3299349813528395'; 

  // Initialize the Facebook SDK
  window.fbAsyncInit = () => {
    this.initializeFacebookSDK(appId);
  };

  // Load the Facebook SDK asynchronously
  this.loadFacebookSDK();
}

private initializeFacebookSDK(appId: string) {
  window.FB.init({
    appId: appId,
    cookie: true,
    xfbml: true,
    version: 'v12.0', // The Graph API version
  });

  // Attempt to log the user in
  this.attemptLogin();
}

private loadFacebookSDK() {
  (function (d: Document, s: string, id: string) {
    if (d.getElementById(id)) return; // Prevent loading the SDK multiple times

    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js"; // SDK source

    const fjs = d.getElementsByTagName(s)[0];
    if (fjs && fjs.parentNode) { // Check if parentNode is not null
      fjs.parentNode.insertBefore(js, fjs);
    }
  }(document, 'script', 'facebook-jssdk'));
}

private attemptLogin() {
  window.FB.login((response: { status: string; authResponse?: { accessToken: string; }; }) => {
   // console.log("response :"+response);
   // console.log("response AuthResponse :"+response.authResponse);
   // console.log("response status :"+response.status);
    if (response.status === 'connected' && response.authResponse) {
      // User is logged in, fetch their info
      this.getUserInfo(response.authResponse.accessToken);
    } else {
     // console.error('User cancelled login or did not fully authorize.');
      AlertService.sendMessage('Login cancelled or failed. Please try again.');
    }
  }, { scope: 'email' }); // Request permission to access email
}

private getUserInfo(accessToken: string) {
  window.FB.api('/me', { fields: 'name,email' }, (user: { name: string; email: string; }) => {
    if (user && user.email) {
     // console.log('User Info:', user); // Log the user info
       this.submitted=true;
      // Attempt to log the user in on your backend
      this.authenticateUser(accessToken, user);
    } else {
     // console.error('Failed to fetch user information.');
      AlertService.sendMessage('Failed to fetch user information. Please try again.');
    }
  });
}

private authenticateUser(accessToken: string, user: { name: string; email: string }) {
  AuthService.facebookLogin({
    accessToken,
    name: user.name,
    email: user.email
  })
  .then((response: { data: { isAuthorized: boolean; }; }) => {
    if (response.data.isAuthorized) {
      // Store the token and navigate to the user page
      
      AuthUser.setToken(response.data);
      this.$router.push({ path: "user" });
    } else {
      // Handle the case where authorization failed
      AlertService.sendMessage('Authorization failed. Please try again.');
    }
  })
  .catch((error: any) => {
    //console.error('Facebook login error:', error); // Log any errors
    AlertService.sendMessage('An error occurred during login. Please try again.');
  });
}


};
